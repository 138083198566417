<template>
  <div class="main" data-title="销售分析" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">
          <span class="name">销售分析</span>
          <div class="page-search">
            <select-dep @setDeps="setDeps" ref="selectDep"></select-dep>
            <el-date-picker
              :clearable="false"
              @change="getNewData"
              end-placeholder="结束月份"
              format="yyyy年MM月"
              range-separator="-"
              start-placeholder="开始月份"
              style="margin-left:20px"
              type="monthrange"
              v-model="key"
              value-format="yyyy-MM"
            ></el-date-picker>
          </div>
        </h2>
      </div>
    </div>
    <el-card class="main-content" shadow="hover" v-if="!isData">
      <div class="no-data">暂无数据</div>
    </el-card>
    <div class="chart" v-else>
      <el-row :gutter="20">
        <el-col :span="14">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-card shadow="hover">
                <div class="jue-card-header">
                  <div class="jue-card-title">进度对比</div>
                </div>
                <ve-bar :data="chartData.chart1"></ve-bar>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-card shadow="hover">
                <div class="jue-card-header">
                  <div class="jue-card-title">销售过程管控</div>
                </div>
                <ve-histogram :data="chartData.chart2"></ve-histogram>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">指标达成情况</div>
            </div>
            <ve-bar :data="chartData.chart3" height="924px"></ve-bar>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import SelectDep from '@/components/chart/SelectDep.vue'
import VeBar from 'v-charts/lib/bar.common'
import VeHistogram from 'v-charts/lib/histogram.common'
export default {
  components: {
    SelectDep,
    VeBar,
    VeHistogram
  },
  data() {
    return {
      chartH: '300px',
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: [],
      isData: false,

      chartData: {}
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    getNewData() {
      this.getListData()
    },
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    getListData() {
      let _this = this
      this.$axios
        .post('/statistic/chart1', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            this.isData = true
            this.chartData = res.data.data.list
          } else {
            this.isData = false
          }
        })
    },

    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
  overflow-x: auto;
}
.chart {
  padding: 20px;
  /deep/ .el-row {
    margin-bottom: 20px;
  }
}
</style>